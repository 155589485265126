import { Injectable } from '@angular/core';
import { Storage } from "@ionic/storage-angular";

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage: Storage | null = null;
  public ready: Promise<any>;

  constructor(private storage: Storage) {
    this.init();
  }

  init() {
    const promise = this.storage.create();
    promise.then(
      storage => this._storage = storage
    )

    return promise;
  }

  set(key: string, value: any) {
    this._storage?.set(key, value);
  }

  get(key: string): Promise<any> {
    return this._storage.get(key);
  }

  remove(key: string) {
    this._storage?.remove(key);
  }
}