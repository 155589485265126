import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { SecurityService } from './security.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  
  constructor(
    private security: SecurityService
  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // const token = "my-token-string-from-server";

    // //Authentication by setting header with token value
    // if (token) {
    //   request = request.clone({
    //     setHeaders: {
    //       'Authorization': token
    //     }
    //   });
    // }

    // if (!request.headers.has('Content-Type')) {
    //   request = request.clone({
    //     setHeaders: {
    //       'content-type': 'application/json'
    //     }
    //   });
    // }

    // request = request.clone({
    //   headers: request.headers.set('Accept', 'application/json')
    // });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // console.log('event--->>>', event);
          // this.security.refreshJWT()
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log(error)
        if (error.error.name === "JsonWebTokenError") {
          this.security.logout()
        }
        return throwError(error);
      }));
  }

}