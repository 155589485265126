import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from '../security.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private security: SecurityService,
    private router: Router
  ) {

  }

  canActivate (): boolean {
    if (this.security.checkForToken()) {
      return true
    } else {
      this.router.navigate(['home-auth'])
      return false
    }
  }
}
