import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertCustomCloseBtnService {

  constructor(private alertCtrl: AlertController) { }

  registerCloseBtns(){
    const elements = document.querySelectorAll('.alert-ctrl-btn');
      elements.forEach( element => {
        element.addEventListener('click', async event => {
            const alert = await this.alertCtrl.getTop();
            if(alert){
              alert.dismiss();
            }
        });
    });
  }

}
