import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserIdService {

  private userId = new BehaviorSubject<string>("default");
  currentuserId = this.userId.asObservable();

  constructor() { }

  setUserId(id: string) {
    this.userId.next(id)
  }
}


