import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from "../storage.service";
import { ToastController } from "@ionic/angular";

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly _endpoint: string;
  private _token: string | null = null;

  constructor(
    protected http: HttpClient,
    private storage: StorageService,
    private toast: ToastController,
  ) {
    this._endpoint = environment.apiEndpoint;
  }

  get endpoint(): string {
    return this._endpoint;
  }

  get token(): string | null {
    return this._token;
  }

  set token(value: string | null) {
    this.storage.set('jwt_token', value);
    this._token = value;
  }

  public getUrl(url: string): string {
    return `${this._endpoint}${url}`;
  }

  public getOptions(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'X-ZUMO-AUTH': `${this._token}`,
        'zumo-api-version': '2.0.0'
      }),
    };
  }

  public getToken(): { headers: HttpHeaders } {
    return {
      headers: new HttpHeaders({
        'token': `${this._token}`,
        'zumo-api-version': '2.0.0'
      }),
    };
  }

  async toastError(err) {
    const toast = await this.toast.create({message: "Une erreur s'est produite", duration: 3000});
    await toast.present();
    console.log(err)
  }
}