import { Injectable } from '@angular/core';
import { LoginJWT } from "../../models/login-jwt";
import { User } from "../../models/user";
import { Profile } from "../../models/profile";

import { ApiService } from "./api.service";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { StorageService } from "../storage.service";

import { flatMap } from "rxjs/internal/operators";



@Injectable({
  providedIn: 'root'
})


export class UserService {
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private storage: StorageService,
  ) {}

  // register(body): Observable<any> {
  //   return this.http.post(this.api.getUrl('/api/register'), body);
  // }

  login(body) {
    return this.http.post<LoginJWT>(this.api.getUrl('/api/login'), body, {
      headers: new HttpHeaders().set('zumo-api-version', '2.0.0')
    });
  }

  forget(body) {
    return this.http.post(this.api.getUrl('/api/forgot'), body, {
      headers: new HttpHeaders().set('zumo-api-version', '2.0.0')
    });
  }

  getCurrent(): Observable<User> {
    return this.http.get<User>(this.api.getUrl('/tables/user'), this.api.getOptions());
  }

  refreshToken() {
    return this.http.get(this.api.getUrl('/api/regenerate'), this.api.getToken());
  }
}
