import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { 
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';

// STORAGE
import { IonicStorageModule } from "@ionic/storage-angular";
import { StorageService } from './services/storage.service';

// CHARTS
import { NgChartsModule } from 'ng2-charts';

// ANGULAR MATERIALS
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// PLAY AUDIO
import { AuthGuard } from './services/auth/auth.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HttpConfigInterceptor } from './services/httpConfig.interceptor';



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    NgChartsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, 
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
  constructor(private storageService: StorageService) {}
}
