import { AlertCustomCloseBtnService } from './alert-custom-close-btn.service';
import { Injectable } from '@angular/core';
import { StorageService } from "./storage.service";
import { UserService } from "./api/user.service";
import { ApiService } from "./api/api.service";

import { User } from "../models/user";
import { UserId } from '../models/user-id';

import { Observable, of } from "rxjs";
import { concatMap, finalize, map, tap } from "rxjs/operators";
import { AlertController, LoadingController } from "@ionic/angular";
import { Router } from "@angular/router";
import { UserIdService } from './user-id.service';



@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  private _user: User | null = null;
  userObservable: Observable<any>;
  userObserver: any;

  private _jwt: string | null = null;
  jwtExpiration: Date | null = null;
  roles: string[] = [];

  constructor(
    private storage: StorageService,
    private userService: UserService,
    // private profileService: ProfileService,
    private api: ApiService,
    private alertController: AlertController,
    private router: Router,
    private loadingController: LoadingController,
    // To get the current UserId
    private UserIdService: UserIdService,
    private alertCustomCloseBtn: AlertCustomCloseBtnService
  ) {
    this.userObservable = new Observable<any>(
      subscriber => {
        this.userObserver = subscriber;
        this.init();
      },
    )
  }

  async init() {
    await this.storage.init();
    const jwt = await this.storage.get('jwt_token');

    // Code added by Thomas
    if (jwt === null) {
      this.jwt = localStorage.getItem('jwt_token')
    }

    if (jwt === null) {
      this.userObserver.next(null);
      this.userObserver.complete();
      return;
    } else {
      this.jwt = jwt;
    }

    this.userService.getCurrent().subscribe(
      user => {
        this.user = user;
        this.userObserver.next(this.user);
        this.userObserver.complete();
      },
      () => {
        this.userObserver.next(null);
        this.userObserver.complete();
      },
    )
  }

  refreshJWT() {
    this.userService.refreshToken().subscribe(res => {
      this.jwt = res['token']
      localStorage.setItem("jwt_token", res['token'])
    })
  }

  refreshUser() {
    this.userService.getCurrent().subscribe(user => this.user = user);
  }

  // refreshProfile() {
  //   if (this.user.profile?.uuid) {
  //     this.profileService.get(this.user.profile.uuid).subscribe(profile => this.user.profile = profile);
  //   }
  // }

  login(body: { email: string, password: string, mobile: boolean }) {
    const obs = this.userService.login(body)
      .pipe(
        tap(async JWTToken => {
          this.jwt = JWTToken.token
          /*****/
          localStorage.setItem("jwt_token", JWTToken.token)
          this.UserIdService.setUserId(JWTToken['userId'])
          localStorage.setItem("user_id", JWTToken['userId'])
          /*****/
        }),
        
        map(JWTToken => JWTToken.token),
        concatMap(() => this.userService.getCurrent()),
      );

    this.loadingController.create({message: 'Connexion'}).then(
      loader => {
        loader.present().then(
          () => {
            obs
              .pipe(finalize(() => loader.dismiss()))
              .subscribe(
                user => {
                  console.log(user);
                  this.user = user[0];
                  this.router.navigate(['']);
                },
                async () => {
                  /*
                  const alert = await this.alertController.create({
                    message: 'Identifiants incorrects',
                    buttons: ['Ok'],
                  });
                  await alert.present();
                  */
                  await this.alertController.create({
                    cssClass: 'alert-email',
                    header: 'Erreur',
                    //subHeader: "x",
                    message: 'L\'email ou le mot de passe ne sont pas corrects. Merci de vérifier vos identifiants <ion-button class="alert-ctrl-btn">OK</ion-button>',
                    buttons: [
                      {
                        text: 'X',
                        role: 'cancel'
                      },
                    ]
                  }).then(async res => {
                    await res.present();
                    this.alertCustomCloseBtn.registerCloseBtns();
                  });
                },
              )
          }
        )
      }
    )

    return obs;
  }

  logout() {
    this.user = null;
    this.jwt = null;

    // Code added by Thomas
    localStorage.removeItem('jwt_token')
    localStorage.removeItem('user_id')
    this.router.navigate(['/login']);
  }

  set user(user: User | null) {
    this.userObservable = of(user as User);
    this._user = user;
  }

  get user(): User | null {
    return this._user;
  }

  get jwt(): string | null {
    return this._jwt;
  }

  set jwt(token: string | null) {
    this._jwt = token;
    this.api.token = token;
    if (token !== null) {
      this.storage.set('jwt_token', token);
      const jwt = SecurityService.parseJwt(token);
      this.jwtExpiration = new Date(jwt.exp * 1000);
      this.roles = jwt.roles;

      // Code added by Thomas
      localStorage.setItem('jwt_token', token)
    } 
    else {
      this.storage.remove('jwt_token');
    }
  }

  private static parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

    return JSON.parse(jsonPayload);
  };

  // For authguard
  checkForToken(): boolean | null {
    // code added by Thomas
    if (this.jwt === null) {
      this.jwt = localStorage.getItem('jwt_token')
      this.UserIdService.setUserId(localStorage.getItem('user_id'))
    }
    return !!this.jwt
  }
}